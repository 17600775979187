import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import '../../src/assets/scss/pages/_contact.scss'

class ContactPage extends React.Component {

    render() {

       return (
            <Layout>
                <div>
                <Helmet title={`Contact`} />
                <div className="content">
                  <div className="content__inner">
                    <div className="page">
                      <h1 className="page__title">Contact</h1>
                      <div className="page__body">
                        <div className="contact">
                            <p>お気軽にお問い合わせください。</p>

                            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="thanks">

                                <input type="hidden" name="bot-field" />
                                <input type="hidden" name="form-name" value="contact" />

                                <div className="contact_items">

                                    <div className="contact_items_item">
                                        <p className="contact_items_item_label">Name</p>
                                        <input type="text" name="name" id="name" className="contact_items_item_input" placeholder="Jorge" required autoComplete="name" />
                                    </div>

                                    <div className="contact_items_item">
                                        <p className="contact_items_item_label">Email</p>
                                        <input type="email" name="email" id="email" className="contact_items_item_input" placeholder="example@gmail.com" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required autoComplete="email" />
                                    </div>
                                    <div className="contact_items_item">
                                        <p className="contact_items_item_label message">Message</p>
                                        <textarea className="contact_items_item_textarea" name="message" id="message" row="5" required></textarea>
                                    </div>
                                    <button type="submit" className="contact_items_btn">Send Message</button>
                                </div>                                                         
                               
                            </form>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Sidebar {...this.props} />
                </div>
            </Layout>
        )
    }
}

export default ContactPage

export const pageQuery = graphql`
  {
    site {
        siteMetadata {
          title
          subtitle
          copyright
          menu {
            label
            path
          }
          author {
            name
            #email
            twitter
            github
          }
        }
      }
  }
`